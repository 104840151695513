import axios from "axios";
import Vue from 'vue'
import { i18n } from '../plugins/i18n';

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const BASE_API_URL = API_ORIGIN + '/api/v1';

// Authorization header is inserted automatically to each request (if token is present)
export const authInterceptor = [
  config => {
    if (Vue.prototype.$token()) {
      config.headers['Accept-language'] = i18n.locale
      config.headers.Authorization = `Bearer ${Vue.prototype.$token()}`;
    }
    return config;
  },
  error => Promise.reject(error)
];

export const Service = (url) => axios.create({
  baseURL: BASE_API_URL + url,
});

export const timezoneInterceptor = [
    config => {
      if (localStorage.getItem("userTimeZone") && localStorage.getItem("userTimeZone") !== 'null') {
        config.headers['X-Timezone'] = localStorage.getItem("userTimeZone");
      }
      return config;
    },
    error => Promise.reject(error),
  ];

export const AuthorizedService = (url) => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  authorizedService.interceptors.request.use(...timezoneInterceptor);
  return authorizedService
};
